import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Categories from "../components/Categories";
import Contact from "../components/Contact";
import Layout from "../components/Layout";
import MarkdownContent from "../components/markdown";
import world from "../../static/img/world.svg";

export const IndexPageTemplate = ({
  heading,
  description,
}) => (
  <div>
    <div className="hero">
      <div className="hero-img-section">
        <img src={world} className="w-100" alt="map" />
      </div>
      <div className="hero-text-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 offset-md-6">
              <h1>
                {heading}
              </h1>
              <MarkdownContent className="main-subtitle" content={description} />

              <Link to="/about" className="btn btn-primary">
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="pt-5">
      <Categories />
    </div>
    <div className="contact-bg py-5">
      <Contact />
    </div>
  </div>
);

IndexPageTemplate.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        heading={frontmatter.heading}
        description={frontmatter.description}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        heading
        description
      }
    }
  }
`;
