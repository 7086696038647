import React, { Component } from "react";

class Contact extends Component {
  render() {
    return (
      <div className="container">
        <h2 className="mt-0">Contact Us</h2>
        <div className="row">
          <div className="col-md-4">
            <p>
              Please send us a message and we will get back to you within 2 working days.
            </p>
            Email:{" "}
            <a href="mailto:contact@farenthesis.com">contact@farenthesis.com</a>
            <br/>
            Phone:
            <a href="tel:+919322339011">+91-932 233 9011</a>
          </div>
          <div className="col-md-6 offset-md-2">
            <form action="https://formspree.io/meqrvrkg" method="POST">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  placeholder="Your Name"
                />
              </div>
              <div className="form-group">
                <input
                  className="form-control"
                  type="email"
                  name="_replyto"
                  placeholder="Your Email"
                />
              </div>
              <div className="form-group">
                <input
                  className="form-control"
                  type="tel"
                  name="phone"
                  placeholder="Your Phone Number"
                />
              </div>
              <div className="form-group">
                <textarea
                  name="message"
                  className="form-control"
                  placeholder="Your Message"
                  rows="4"
                ></textarea>
              </div>
              <button className="btn btn-primary btn-block">Submit</button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
